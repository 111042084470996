import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter } from '@angular/core';
import { GSDropdownControl } from '../../controls/gsControls';

export class FormDropdownProperty extends FormProperty {
  options?: any[];
  source: any;
  type = PropertyTypes.Dropdown;
  component = GSDropdownControl;
  changed = new EventEmitter<any>();
  selectedValue: any;
  infoMessage;

  constructor(
    name: string,
    label: string,
    options?: [],
    row?: number,
    column?: number,
    rowspan?: number,
    columnspan?: number,
    valid?: boolean,
    public autoDisplayFirstDisabled?: boolean,
    public placeholder?: string
  ) {
    super(name, label, row, column, rowspan, columnspan, valid);
    this.options = options ? options : this.options;
  }
}

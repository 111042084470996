<div *ngIf="item" class="row form-property">
  <div
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
  >
    <span class="p-float-label label-remove-opacity">
      <p-dropdown
        [appendTo]="'body'"
        id="{{name}}"
        [options]="options"
        (onChange)="change($event)"
        [ngModel]="value"
        [readonly]="readonly"
        [placeholder]="property.placeholder||'general.pleaseSelect'|gstranslate"
        [ngClass]="{'disabled':readonly, 'dropdown-property-invalid':notValid}"
        (keydown)="keydown($event)"
        class="disabled ui-inputwrapper-focus ui-inputwrapper-focus"
        [style]="{'width': '100%'}"
        [autoDisplayFirst]="!property.autoDisplayFirstDisabled"
        optionDisabled="isDisabled"
        [showClear]="true"
      >
        <ng-template let-opt pTemplate="item">
          <ng-container *ngIf="!opt.description">
            <span>{{opt.label}}</span>
          </ng-container>
          <ng-container *ngIf="opt.description">
            <div class="row">
              <div class="col-lg-11 col-sm-11 col-md-11">
                <span> {{opt.label}} </span>
              </div>
              <div class="col-lg-1 col-sm-1 col-md-1" style="padding: 0">
                <span pTooltip="{{opt.description}}">
                  <i class="fas fa-info-circle master-color"></i>
                </span>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </p-dropdown>
      <label
        *ngIf="!hideLabel"
        class="gs-float-label {{property.labelClass ? property.labelClass : ''}}"
        [ngClass]="{'error-color':notValid}"
        >{{label}}</label
      >
      <span
        *ngIf="notValid"
        class="form-error"
        pTooltip="{{property.validationMessage}}"
        tooltipPosition="top"
      >
        <i
          class="fas fa-info-circle fa-2x form-valid-icon with-icon error-color"
        ></i>
      </span>
      <span
        style="color: red; font-style: italic"
        class="text-s"
        *ngIf="!property.options && property.infoMessage"
      >
        {{property.infoMessage}}
      </span>
    </span>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
